<template>
  <tr :class="'upload' + upload.id">
    <td class="flex align-items-center">
      <div class="checkbox">
        <input type="checkbox" :id="'checkbox' + upload.id" v-model="isChecked"/>
        <label :for="'checkbox' + upload.id"><span class="checkbox-icon"></span></label>
      </div>
    </td>
    <td class="p-2">
      <router-link class="font-semibold mr-1"
                   :to="{name: 'UploadEdit', params: {id: upload.id}}">
        {{ upload.title ? upload.title : upload.link }}
      </router-link>
      <div class="vov-program mt-3">
        <Embed :type="upload.file.type"
               :unique="Math.round(Math.random() * 1000000) + 'upload' + upload.id"
               :key="Math.round(Math.random() * 1000000) + 'upload' + upload.id"
               :src="upload.file.type === 'audio' ? upload.file.audio_url : upload.file.video_url" 
               v-if="upload.file"/>
        <a :href="'#modal-' + upload.id" uk-toggle v-if="upload.thumbnail">
          <img :src="upload.thumbnail.url" class="w-20 h-20 object-cover mt-3"/>
        </a>
        <div :id="'modal-' + upload.id" uk-modal v-if="upload.thumbnail">
          <div class="uk-modal-body uk-modal-dialog rounded-md shadow-2xl">
            <img :src="upload.thumbnail.url"/>
            <p>{{ upload.content }}</p>
          </div>
        </div>
        <div v-if="upload.note" class="text-xs mt-3">{{ upload.note }}</div>
        <div v-if="upload.link">
          <p>File gốc:</p>
          <Embed :showInfoProp="true" 
              :unique="'raw' + Math.round(Math.random() * 1000000) + 'upload' + upload.id"
              :key="'raw' + Math.round(Math.random() * 1000000) + 'upload' + upload.id"
              :src="upload.link"/>
        </div>
      </div>

      <div class="mt-3 space-x-2">
        <span>
          <router-link v-if="upload.user" class="font-semibold whitespace-nowrap"
                       :to="{name: 'UserDetail', params: {id: upload.user.id}}">
            {{ upload.user.username }}
          </router-link>
          ;
        </span>
        <span v-if="upload.composers">Sáng tác: {{ upload.composers }};</span>
        <span v-if="upload.artists">Trình bày: {{ upload.artists }};</span>
        <span v-if="upload.poets">Thơ: {{ upload.poets }};</span>
        <span v-if="upload.recomposers">Soạn lời: {{ upload.recomposers }};</span>
        <span v-if="upload.fcats">Thể loại: {{ upload.fcats }};</span>
        <span v-if="upload.melodies">Làn điệu: {{ upload.melodies }};</span>
        <span>{{ timeago(upload.created_at) }}</span>
      </div>

      <div v-if="upload.reason && upload.status === 'rejected'" class="mt-3">
          {{ upload.reason }}
        </div>

      <div class="flex justify-content-between mt-3">
        <div class="flex items-center space-x-2">
          <div>
            <span
                :class="'whitespace-nowrap inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none rounded-full ' + getUploadTypeClass(upload.type)">{{
                getUploadTypeText(upload.type)
              }}</span>
          </div>

          <div>
            <span v-if="upload.status === 'pending'"
                  class="px-2 py-1 text-xs rounded-full bg-gray-500 text-white whitespace-nowrap">Đang chờ</span>
            <span v-if="upload.status === 'not_sure'"
                  class="px-2 py-1 text-xs rounded-full bg-gray-500 text-white whitespace-nowrap">Chưa chắc chắn</span>
            <span v-if="upload.status === 'approved'"
                  class="px-2 py-1 text-xs rounded-full bg-green-500 text-white whitespace-nowrap">Đồng ý</span>
            <span v-if="upload.status === 'rejected'" 
                  class="mr-l px-2 py-1 text-xs rounded-full bg-red-500 text-white whitespace-nowrap">Từ chối</span>
            <span class="ml-1 px-2 py-1 text-xs rounded-full bg-blue-700 whitespace-nowrap">
              <router-link :to="{name: 'UploadDetail', params: {id: upload.id}}" class="text-white">
                Chi tiết
              </router-link>
            </span>
          </div>

          <div v-if="upload.processor">
            <span class="px-2 py-1 text-xs rounded-full bg-gray-500 text-white whitespace-nowrap">{{upload.processor.username}}</span>
          </div>
        </div>

        <div class="flex items-center space-x-2">
          <button v-if="upload.status === 'pending' || upload.status === 'not_sure'" uk-toggle="target: #approve-upload-modal"
                  class="px-2 py-1 text-xs rounded-full bg-green-500 text-white" v-on:click="approve(upload)">Đồng ý
          </button>
          <button v-if="upload.status === 'pending' || upload.status === 'not_sure'" uk-toggle="target: #reject-upload-modal"
                  class="px-2 py-1 text-xs rounded-full bg-red-500 text-white" v-on:click="reject(upload)">Từ chối
          </button>
          <button v-if="upload.status === 'pending'" uk-toggle="target: #not_sure-upload-modal"
                  class="px-2 py-1 text-xs rounded-full bg-gray-500 text-white" v-on:click="notSure(upload)">Chưa chắc chắn
          </button>
          <button v-if="upload.status === 'pending'" uk-toggle="target: #delete-upload-modal"
                  class="px-2 py-1 text-xs rounded-full bg-red-500 text-white" v-on:click="remove(upload)">Xóa
          </button>
          <a class="text-blue inline-flex items-center h-5 px-3 m-2 text-sm transition-colors duration-150 bg-purple-100 focus:shadow-outline hover:bg-purple-200 rounded-lg"
             :href="upload.result" v-if="upload.status === 'approved'">Kết quả</a>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>

import {
  getUploadTypeClass,
  getUploadTypeText,
  timeago
} from "../../../core/services/utils.service";
import Embed from "../../Embed";

export default {
  name: "AdminUploadItem",
  components: {Embed},
  props: {
    upload: Object
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    timeago(x) {
      return timeago(x);
    },
    getUploadTypeText(x) {
      return getUploadTypeText(x);
    },
    getUploadTypeClass(x) {
      return getUploadTypeClass(x);
    },
    approve(upload) {
      this.$emit("approveUpload", upload);
    },
    notSure(upload) {
      this.$emit("notSureUpload", upload);
    },
    reject(upload) {
      this.$emit("rejectUpload", upload, this.reason);
    },
    remove(upload) {
      this.$emit("deleteUpload", upload);
    },
    cleanNote(note) {
      if (note) {
        return note.replace(":", "");
      }

      return "";
    }
  },
  created() {
    this.isChecked = this.upload.is_checked;
  },
  watch: {
    "upload.is_checked": {
      handler(newVal) {
        this.isChecked = newVal;
      },
      deep: true
    },
    isChecked: {
      handler(newVal) {
        this.$emit("checkedUpload", this.upload, newVal);
      }
    },
  }
}
</script>
